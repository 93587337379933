@font-face {
font-family: 'Proxima Nova';
src: local('Proxima Nova'), url(./resource/fonts/ProximaNova-Regular.woff2) format('woff2');
font-weight: normal;
}

@font-face {
font-family: 'Proxima Nova';
src: local('Proxima Nova'), url(./resource/fonts/ProximaNova-Semibold.woff2) format('woff2');
font-weight: 600;
}

@font-face {
font-family: 'Proxima Nova';
src: local('Proxima Nova'), url(./resource/fonts/ProximaNova-Bold.woff2) format('woff2');
font-weight: bold;
}

@font-face {
font-family: 'Proxima Nova';
src: local('Proxima Nova'), url(./resource/fonts/ProximaNova-Black.woff2) format('woff2');
font-weight: 900;
}

body {
    font-family: Proxima Nova;
}

.left {
    left: -100%;
    transition: left 0.5s;
    position: fixed;
}
.right {
    left: 0;
    transition: left 0.5s;
}
@media screen and (max-width: 768px) {
    #root {
      /* height: 100vh;
      overflow-y: hidden;   */
      font-family: Proxima Nova;
    }
    .custom-button {
       position: absolute;
       left: 10px;
    }
    .desktopDevice.card-body {
        display: none;
    }
}

.card-header {
    background: #1D1D25;
    border-bottom: none;
    padding: 30px 24px 0 24px;
}

.item-card .card-header {
    padding: 24px 24px 0 24px;
}

.card-title {
    margin-bottom: 0;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;

    /* identical to box height */

    /* Semiwhite */
    color: #EFF3FB;
    text-align: left;
}

.card-body {
    padding: 24px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.card-subtitle {
    font-family: Proxima Nova;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;

color: #B7BECB;
}

.card-text {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;

    /* identical to box height */

    /* Semiwhite */
    color: #EFF3FB;
}

.card,
.card-header,
.card-body {
    background: #1D1D25;
    border-radius: 8px !important;
}

@media screen and (min-width: 769px) {
    .mobileDevice {
        display: none;
    }
}

.pageDiv .page-link {
    background: #1D1D25;
    border: 1px solid #B7BECB;
    box-sizing: border-box;
    border-radius: 2px !important;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;

    /* or 100% */
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    width: 32px;
    height: 32px;
}

.pageDiv .page-item {
    margin-right: 8px;
}

.pageDiv .page-item:last-child {
    margin-right: 0;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1969FF !important;
    border-color: #1969FF !important;
}

.form-control,
.custom-select {
    height: unset;
    padding: 1rem .75rem;
}

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #1D1D25;
    color: #707B8F;
}

.custom-select::-ms-expand {
    display: none;
}

textarea.form-control {
    resize: none;
}