.card-header {
    background-color: #1D1D25;
    border-width: 0;
    color: #B7BECB;
    font-size: 16px;
}
.spinner-border {
    position: fixed !important;
    top: 50% !important;
    margin-left: 40% !important;
}
.nav {
    justify-content: flex-end;
}
.sub-card .card-header-pills {
    margin-left: auto;
    border: 1px solid #1969FF;
    box-sizing: border-box;
    border-radius: 2px;
}

.card-header-pills > a {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;

    /* identical to box height, or 100% */
    display: flex;
    align-items: center;
    text-align: center;

    /* Semiwhite */
    color: #EFF3FB;

    opacity: 0.5;
    padding: 9px 8px;
}

.card-header-pills > a.active {
    /* Semiwhite */
    color: #EFF3FB;
    background: #1969FF;
    border: 1px solid #1969FF;
    box-sizing: border-box;
    border-radius: 2px;
    opacity: 1;
}

.main-card {
    border-radius: 8px!important; 
    display: flex!important;
    flex-direction: row!important;
    background-color: #121318!important;
}
.sub-card {
    width: 50%;
    background-color: rgb(29, 29, 37)!important;
}
.info-card {
    margin-left: 20px;
    width: 50%;
    background-color: #121318!important;
}
.item-card {
    margin-bottom: 12px;
    height: 32%;
    background-color: #1D1D25!important;
}
.item-card:last-child {
    margin-bottom: 0;
}
.pageDiv {
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: 6px;
    margin-left: auto;
}
@media screen and (max-width: 768px) {
    .spinner-border {
        left: 5% !important;
    }
    .main-card {
        flex-direction: column!important;
    }
    .sub-card {
        width: 100%;
    }
    .info-card {
        width: 100%;
        background-color: #14151A!important;
        margin-left: 0;
        margin-top: 20px;
    }
    .item-card {
        height: unset;
        margin-bottom: 30px;
        background-color: #1D1D25 !important;
    }
    .desktopDevice {
        display: none;
    }
    .pageDiv {
        justify-content: center;
        margin: 10px 0 0;
    }
    .sub-card .card-header-pills {
        margin: 10px 0 10px 0;
    }
}

.tab-content {
    width: 100%;
}

.sub-card .card-body {
    padding-top: 0;
}

.details-nav {
    justify-content: flex-start;
}

.details-nav.nav-pills .nav-link.active,
.details-nav.nav-pills .show>.nav-link {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */
    text-align: center;

    color: #FFFFFF;
    background-color: transparent;
    border: 1px solid rgba(25, 105, 255, .5);
    box-sizing: border-box;
    border-radius: 2px;
}

.details-nav.nav-pills {
    margin: 0;
}

.details-nav.nav-pills .nav-link {
    padding: 5px;
    border: 1px solid rgba(255, 255, 255, .5);
    
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */
    text-align: center;

    /* Semiwhite */
    color: #EFF3FB;

    opacity: 0.5;
}

.details-nav.nav-pills>.nav-item {
    margin-right: 6px;
}

.details-nav.nav-pills>.nav-item:last-child {
    margin-right: 0;
}