.form-label {
    margin-bottom: .5rem;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* grey */
    color: #B7BECB;
}

.form-control {
    background-color: #1D1D25!important;
    border-color: #1D1D25!important;
    color: #B7BECB!important;
    box-shadow: none !important;
}
.form-control:focus {
    color: #B7BECB;
    box-shadow: none !important;
}
.custom-select {
    background-color: #1D1D25;
    border-color: #1D1D25;
    color: #B7BECB!important;
    box-shadow: none !important;
}
.custom-select:focus {
    border-color: #1D1D25;
}
.custom-select:disabled {
    background-color: #1D1D25;
}
.custom-select > option {
    background-color: #1D1D25!important;
    border-color: #1D1D25!important;
    color: #B7BECB!important;
    line-height: 50px;
    height: 50px !important;
}
#projectForm .input-group-text {
    background-color: #1D1D25!important;
    border-color: #1D1D25!important;
    color: #B7BECB!important;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 0;
}

#projectForm .input-group-prepend ~ .form-control {
    padding-left: 0;
    outline: none;
}

#projectForm .input-group-prepend ~ .form-control:focus {
    outline: none;
    box-shadow: none;
}

.btn-primary {
    background: #1969FF;
    border-radius: 8px;
    width: 250px;
    height: 48px;
}
.btn-secondary,
.btn-danger {
    border-radius: 8px;
    width: 250px;
    height: 48px;
}

.form-row {
    justify-content: center;
}

.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-valid, .was-validated .form-control:valid {
    border: none;
    background-position: right calc(.75em + 1rem) center !important;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    background-position: top calc(1em + .1875rem) right calc(.75em + 1rem) !important;
}

.custom-select.is-valid, .was-validated .custom-select:valid,
.custom-select.is-valid, .was-validated .custom-select:valid {
    border: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat !important;
    background-color: #1D1D25!important;
    appearance: none;
    padding-right: calc(1.5em + .75rem);
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border: none;
    background-position: right calc(.75em + 1rem) center !important;
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    background-position: top calc(1em + .1875rem) right calc(.75em + 1rem) !important;
}
.custom-select.is-invalid, .was-validated .custom-select:invalid {
    border: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat !important;
    background-color: #1D1D25!important;
    appearance: none;
    padding-right: calc(1.5em + .75rem);
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 1000px #1D1D25 inset !important;
    caret-color: #B7BECB;
}
input:-webkit-autofill
{
 -webkit-text-fill-color: #B7BECB !important;
}

.modal-dialog.modal-reason {
    width: 750px;
    max-width: unset;
}

.modal-dialog .modal-content {
    border: none;
    background-color: #121318;
}

.modal-reason .modal-header {
    background-color: #1D1D25;
    border: none;
    color: #B7BECB;
}

.modal-reason .modal-header button {
    color: #B7BECB;
}

.modal-reason .modal-footer {
    border: none;
}

.logo-upload-button.is-invalid {
    border: 2px solid #dc3545;;
}