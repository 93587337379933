.card-header {
	background-color: #1D1D25;
	border-width: 0;
}
.card {
	border-width: 0!important;
	background-color: #1D1D25;
	border-radius: 8px;
}
@media screen and (max-width: 768px) {
	.desktopDevice {
			display: none;
	}
	.pageDiv {
			justify-content: center;
	}
}
@media screen and (min-width: 769px) {
	.mobileDevice {
			display: none;
	}
}

.pageDiv {
	margin-top: 20px;
}